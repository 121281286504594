const getTimeRemaining = (endTime: string) => {
    const total = Date.parse(endTime) - Date.parse((new Date()).toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const months = Math.floor(total / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(total / (1000 * 60 * 60 * 24)) - (months * 30);

    return {
        total,
        months,
        days,
        hours,
        minutes,
        seconds
    };
}

const formatLabel = (el, value) => {
    el.innerHTML = value === 1 ? el.dataset.labelSingle : value > 1 && value < 5 ? el.dataset.labelFew : el.dataset.labelMany;
}

export const initializeClock = (selector: string) => {
    const clock: HTMLElement = document.querySelector(selector);
    if (!clock) return;

    let endTime = clock.dataset.timestamp
    if (endTime === '' || !endTime) return;


    const monthsSpan = clock.querySelector('[data-countdown-months]');
    const daysSpan = clock.querySelector('[data-countdown-days]');
    const hoursSpan = clock.querySelector('[data-countdown-hours]');
    const minutesSpan = clock.querySelector('[data-countdown-minutes]');
    const secondsSpan = clock.querySelector('[data-countdown-seconds]');

    const updateClock = () => {
        const t = getTimeRemaining(endTime);

        monthsSpan.innerHTML = ('0' + t.months).slice(-2);
        formatLabel(monthsSpan.nextElementSibling, t.months);

        daysSpan.innerHTML = ('0' + t.days).slice(-2);
        formatLabel(daysSpan.nextElementSibling, t.days);

        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        formatLabel(hoursSpan.nextElementSibling, t.hours);

        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        formatLabel(minutesSpan.nextElementSibling, t.minutes);

        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
        formatLabel(secondsSpan.nextElementSibling, t.seconds);

        t.total <= 0 && clearClock();
    }

    const clearClock = () => {
        clearInterval(timeInterval);
        location.reload();
    }

    updateClock();
    const timeInterval = setInterval(updateClock, 1000);
}