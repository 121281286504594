import "@styles/app.sass"
import { initializeClock } from "./partials/count-down";
import { Modal, ModalOpener } from "./partials/modal";
import Rellax from "rellax"

document.addEventListener("DOMContentLoaded", () => {
    initializeClock('[data-event-countdown]');

    if (document.querySelector('[data-parallax]')) {
        new Rellax('[data-parallax]', {
            center: true
        })
    }

    (() => { // Hiding header
        const header = document.querySelector('[data-page-header]') as HTMLElement
        if (!header) return

        const headerOffset = header.offsetTop - window.scrollY,
            headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0

        let lastScroll = 0

        const hidingOnScroll = () => {
            const currentScroll = window.scrollY

            if (currentScroll > headerOffset + headerHeight) {
                header.classList.add('is-sticked')
                header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
                document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
            } else {
                header.classList.remove('is-sticked')
                header.classList.remove('is-up')
                document.body.classList.remove('is-sticked-header')
            }

            lastScroll = currentScroll
        }

        hidingOnScroll()
        window.addEventListener('scroll', () => hidingOnScroll(), { passive: true })

        const navOpener = document.querySelector('[data-nav-opener]')
        navOpener.addEventListener("click", () => {
            document.body.classList.toggle('u-hiddenOverflow')
            navOpener.closest('[data-page-header]')?.classList.toggle('is-opened')
        })
    })();

    (() => { // Speaker modal
        new Modal("#Modal-speakerDetail")
        document.querySelectorAll('[data-modal-opener]')
            .forEach((el: HTMLElement) => new ModalOpener(el))
    })();
})